import { useReducer } from "react"
import DigitButton from "./DigitButton"
import OperationButton from "./OperationButton"
import "./styles.css"
import backspaceIcon from './backspace-svgrepo-com.svg';

export const ACTIONS = {
  ADD_DIGIT: "add-digit",
  CHOOSE_OPERATION: "choose-operation",
  CLEAR: "clear",
  DELETE_DIGIT: "delete-digit",
  EVALUATE: "evaluate",
  SHOW_ANS: "show-ans",
}
let f;
function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.ADD_DIGIT:
      if (state.overwrite) {
        return {
          ...state,
          currentOperand: payload.digit,
          overwrite: false,
        }
      }
      if (payload.digit === "0" && state.currentOperand === "0") {
        return state
      }
      if (payload.digit === "." && state.currentOperand.includes(".")) {
        return state
      }

      return {
        ...state,
        currentOperand: `${state.currentOperand || ""}${payload.digit}`,
      }
      case ACTIONS.SHOW_ANS:
      return{...state,currentOperand:(f - Number(state.previousOperand)).toString()}

    case ACTIONS.CHOOSE_OPERATION:
      if (state.currentOperand == null && state.previousOperand == null) {
        return state
      }

      if (state.currentOperand == null) {
        return {
          ...state,
          operation: payload.operation,
        }
      }

      if (state.previousOperand == null) {
        return {
          ...state,
          operation: payload.operation,
          previousOperand: state.currentOperand,
          currentOperand: null,
        }
      }

      return {
        ...state,
        previousOperand: evaluate(state),
        operation: payload.operation,
        currentOperand: null,
      }
    case ACTIONS.CLEAR:
      return {}
    case ACTIONS.DELETE_DIGIT:
      if (state.overwrite) {
        return {
          ...state,
          overwrite: false,
          currentOperand: null,
        }
      }
      if (state.currentOperand == null) return state
      if (state.currentOperand.length === 1) {
        return { ...state, currentOperand: null }
      }

      return {
        ...state,
        currentOperand: state.currentOperand.slice(0, -1),
        
      }
    case ACTIONS.EVALUATE:
      console.log('ACTIONS.EVALUATE')
      if(state.previousOperand == null && state.currentOperand == 1111)
      {
        console.log("thi is previousOperand" + state.previousOperand);
        console.log("thi is currentOperand" + state.currentOperand);
        if(!f)
        {
          f = window.prompt("Enetr fores value");
        }
        // f = !f && window.prompt("Enetr fores value");
        // f =  state.currentOperand;

        // console.log(f);
      // console.log('this is currentOperand' + state.currentOperand + "thi is previousOperand" + state.previousOperand + "this is operation" ,state.operation);
      }
      if (
        state.operation == null ||
        state.currentOperand == null ||
        state.previousOperand == null
      ) {
        return state
      }
      // let f;
      
      
      return {
        ...state,
        overwrite: true,
        previousOperand: null,
        operation: null,
        currentOperand: evaluate(state,payload,f),
      }
  }
}

function evaluate({ currentOperand, previousOperand, operation },payload,forsitem) {
  const prev = parseFloat(previousOperand)
  const current = parseFloat(currentOperand)
  if (isNaN(prev) || isNaN(current)) return ""
  // let f = 1000;
  let ans;
  let computation1, computation = ""
  switch (operation) {
    case "+":
      console.log("this is prev"+forsitem +" this is prev " + prev + " this is current " + current + " this is f " + f) 
      ans = prev + current;
      computation1 = (forsitem || f) - ans;
      computation = ans;
      console.log('this is payload',payload)
      console.log('this is computation',computation);
   
      console.log('this is f items',f);
      // computation = prev + current;

      break
    case "-":
      computation = prev - current
      break
    case "*":
      computation = prev * current
      break
    case "÷":
      computation = prev / current
      break
     
     
      
      break
  }

  return computation.toString()
}

const INTEGER_FORMATTER = new Intl.NumberFormat("en-us", {
  maximumFractionDigits: 0,
})
function formatOperand(operand) {
  if (operand == null) return
  const [integer, decimal] = operand.split(".")
  if (decimal == null) return INTEGER_FORMATTER.format(integer)
  return `${INTEGER_FORMATTER.format(integer)}.${decimal}`
}

function App() {
  const [{ currentOperand, previousOperand, operation }, dispatch] = useReducer(
    reducer,
    {}
  )
    // const [show,setShow]=useState(false);

  return (
    
    <div className="calculator-grid">
      <div className="output">
        <button className="magicbtn" onClick={()=>dispatch({type:ACTIONS.SHOW_ANS,payload:true})}></button>
        <div className="previous-operand">
          {formatOperand(previousOperand)} {operation}
        </div>
        <div className="current-operand">{formatOperand(currentOperand)}</div>
      </div>
      <button
        className=""
        onClick={() => dispatch({ type: ACTIONS.CLEAR })}
      >
        AC
      </button>
      <button onClick={() => dispatch({ type: ACTIONS.DELETE_DIGIT })}>
      <img src={backspaceIcon} alt="Logo" style={{filter: "invert(1)", width: 30}} />
      </button>
      <DigitButton digit="%" dispatch={dispatch} />
      <OperationButton operation="÷" dispatch={dispatch} className="rounded" />
      <DigitButton digit="7" dispatch={dispatch} />
      <DigitButton digit="8" dispatch={dispatch} />
      <DigitButton digit="9" dispatch={dispatch} />
      <OperationButton operation="&times;" dispatch={dispatch} className="rounded" />
      <DigitButton digit="4" dispatch={dispatch} />
      <DigitButton digit="5" dispatch={dispatch} />
      <DigitButton digit="6" dispatch={dispatch} />
      <OperationButton operation="-" dispatch={dispatch} className="rounded" />
      <DigitButton digit="1" dispatch={dispatch} />
      <DigitButton digit="2" dispatch={dispatch} />
      <DigitButton digit="3" dispatch={dispatch} />
      <OperationButton className="rounded" operation="+" dispatch={dispatch} />
      <DigitButton digit="0" dispatch={dispatch} className="span-two" />
      <DigitButton digit="." dispatch={dispatch} />
      <button
        className="rounded"
        onClick={() => dispatch({ type: ACTIONS.EVALUATE })}
      >
        =
      </button>
    </div>
  )
}

export default App
